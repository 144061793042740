// msal.service.ts
import { Injectable } from '@angular/core';
import * as Msal from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class MsalAccessService {
  private msalInstance: Msal.PublicClientApplication | null = null;
  public shouldUseTokenFromOauth: Boolean = false;

  loadConfig(msalConfig: Msal.Configuration, shouldUseTokenFromOauth: Boolean): void {
    this.msalInstance = new Msal.PublicClientApplication(msalConfig);
    this.shouldUseTokenFromOauth = shouldUseTokenFromOauth
  }

  getMsalInstance(): Msal.PublicClientApplication {
    if (this.msalInstance === null) {
      throw new Error('Msal is not initialized');
    } else {
      return this.msalInstance;
    }
  }

  acquireTokenSilent(request: any): any {
    return this.msalInstance?.acquireTokenSilent(request)
  }

  setIsTokenFromOauth(isFrom: boolean): void {
    localStorage.setItem('isTokenPresentedFromOauth', isFrom.toString())
  }

  getIsTokenFromOauth(): boolean {
    return localStorage.getItem('isTokenPresentedFromOauth') == 'true'
  }

}
