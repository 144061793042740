import { Injectable } from '@angular/core';
import * as Msal from '@azure/msal-browser';
import { MsalAccessService } from './msal-service';
import jwt_decode from 'jwt-decode';


const loginRequest: Msal.RedirectRequest = {
    scopes: ['offline_access', 'User.Read', 'Mail.Read', 'Files.Read.All', 'Calendars.Read', 'Sites.Read.All', 'Chat.Read', 'profile', 'openid']
};


@Injectable({
    providedIn: 'root',
  })

export class Microsoft {

    private accessTokenKey = 'microsoft-access-token'

    public name = 'Microsoft'

    constructor(private msalInstance: MsalAccessService) {}

    login(): void {
        this.msalInstance.getMsalInstance().handleRedirectPromise()
        .then(response => {
            if(!response) {
                if (this.isTokenExpired(this.getToken())) {
                    this.msalInstance.getMsalInstance().loginRedirect({
                        ...loginRequest,
                        loginHint: this.msalInstance.getMsalInstance().getAllAccounts()?.[0]?.username
                    })
                }
            }
            else {
                this.setToken(response.accessToken)
            }
        });
    }

    async updateToken(): Promise<string> {
        try {
          if(this.msalInstance?.getIsTokenFromOauth())
            return Promise.resolve(this.getToken() || '')
          const response = await this.msalInstance?.getMsalInstance()?.handleRedirectPromise();
          if (response !== null) {
            this.setToken(response.accessToken);
            return response.accessToken
          }
          else {
            const account = this.msalInstance.getMsalInstance().getAllAccounts()?.[0]
            if(account) {
                const request = {
                    scopes: loginRequest.scopes,
                    account
                }
                const response = await this.msalInstance.acquireTokenSilent({
                    ...request,
                    forceRefresh: true
                })
                this.setToken(response.accessToken)
                return response.accessToken
            }
            return Promise.resolve('')
          }
        } catch (error) {
            return Promise.resolve('')
        }
    }

    logout(): void {
        this.msalInstance.getMsalInstance().logoutRedirect();
        this.msalInstance.setIsTokenFromOauth(false)
        this.removeToken()
    }

    systemLogout(): void {
        this.msalInstance.setIsTokenFromOauth(false)
        this.removeToken()
    }

    isTokenExpired(token: string | null): boolean {
        try {
            if(token) {
                const decoded: any = jwt_decode(token);
                const currentTime = Math.round(new Date().getTime() / 1000)
                return decoded.exp < currentTime
            }
            return true
        }
        catch {
            return true
        }
    }

    isAuthenticated(): boolean {
        try {
            return !this.isTokenExpired(this.getToken())
        }
        catch {
            return false
        };
    }

    getToken(): string | null {
        return localStorage.getItem(this.accessTokenKey)
    }

    setToken(token: string): void {
        localStorage.setItem(this.accessTokenKey, token)
    }

    removeToken(): void {
        localStorage.removeItem(this.accessTokenKey)
    }

}