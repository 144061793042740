import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { SpyglassComponent } from './spyglass-search/spyglass/spyglass.component';
import { LoginComponent } from './login/login.component';
import { MicrosoftCallbackComponent } from './spyglass-search/microsoft-callback/microsoft-callback.component';
import { OidcCallbackComponent } from './spyglass-search/oidc-callback/oidc-callback.component';
import { AuthGuard } from './auth/auth.guard';
import { NonAuthGuard } from './auth/nonauth.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [NonAuthGuard] },
  { path: '', component: SpyglassComponent, canActivate: [AuthGuard] },
  { path: 'microsoft-callback', component: MicrosoftCallbackComponent, canActivate: [AuthGuard] },
  { path: 'oidc-callback', component: OidcCallbackComponent, canActivate: [NonAuthGuard] }
]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {   
}