import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../config.service';
import { SearchService } from '../search.service';
import { SwirlService } from '../swirl.service'
import { LocalStorageService } from '../local-storage.service'

@Component({
  selector: 'app-spyglass',
  templateUrl: './spyglass.component.html',
  styleUrls: ['./spyglass.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SpyglassComponent implements OnInit {

  hasFacets: boolean = false;
  type: string = '';
  mode: string = localStorage.getItem('isDark') || 'Light';
  isLoading: boolean = false;
  sources = [
    {value: 'default', name: 'All sources', connector: 'default'}
  ];
  isEnabledCharts: boolean = true;


  constructor(private route: ActivatedRoute, private configService: ConfigService, private searchService: SearchService, private swirl: SwirlService) { 
    this.isEnabledCharts = true;
  }

  ngOnInit(): void {
    if(!document.body.classList.contains("dark-theme") && this.mode === 'Dark')
      document.body.classList.toggle("dark-theme")
    this.swirl.getSources().subscribe(async response => {
      response.filter((source: any) => source.active).forEach((source: any) => {
        this.sources.push({
          value: source.id,
          name: source.name,
          connector: source.connector
        })
      })
      const params = this.route.snapshot.queryParamMap;
      const scope = params.get('scope') || 'default';
      this.configService.getConfig(scope).subscribe(config => {
        if(config && !this.isLoading) {
          this.hasFacets = config.facetLayout != null;
          this.type = config['type'];
        }
      });
      this.searchService.isLoading.subscribe(isLoading => this.isLoading = isLoading);
    })
    this.searchService.isEnabledCharts.subscribe(isEnabledCharts => this.isEnabledCharts = isEnabledCharts);
  }

  handleLogoClick() {
    this.searchService.changeEraseEvent(true)
  }
}
