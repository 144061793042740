import { Component, OnInit } from '@angular/core';
import { SwirlService } from '../swirl.service'
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { SearchService } from '../search.service';
import { LocalStorageService } from '../local-storage.service'


@Component({
  selector: 'profile-box',
  templateUrl: './profile-box.component.html',
  styleUrls: ['./profile-box.component.css']
})
export class ProfileBoxComponent implements OnInit {

  user: string = ''
  mode: string = localStorage.getItem('isDark') === 'Dark' ? 'Light' : 'Dark'

  authenticators: { idp: string, expired: boolean }[] | any[] = [];
  isEnabledIframe: Boolean = false;
  isEnabledCharts: boolean = true;

  /**
   * New Autenticator: Add the DI for this class
   * @param swirl        Access to swirl
   * @param authService  Server auth
   * @param router
   * @param configService  config
   * @param msauthService  MS authentication service
   */
  constructor(private swirl: SwirlService, private searchService: SearchService, private authService: AuthService, private router: Router, private localStorageService: LocalStorageService) {
  }

  loadAuthenticators() {
    this.swirl.getAuthenticators().subscribe(response => {
      this.authenticators = response;
    });
  }

  async ngOnInit() {
    this.searchService.isEnabledIframe.subscribe(isEnabledIframe => this.isEnabledIframe = isEnabledIframe)
    this.searchService.isEnabledCharts.subscribe(isEnabledCharts => this.isEnabledCharts = isEnabledCharts)
    this.localStorageService.localStorageChanged.subscribe((value: string) => {
      this.mode = value === 'Dark' ? 'Light' : 'Dark'
    });

    this.user = this.authService.getUser() || ''
    this.loadAuthenticators()
  }

  toggleChange(event: any, idp: string, expired: boolean) {
    if(expired)
      this.authService.redirectToAuth(idp)
    else {
      this.swirl.removeToken(idp).subscribe(() => {
        this.loadAuthenticators()
      })
    }
  }

  toggleTheme() {
    this.localStorageService.setItem('isDark', this.mode)
  }

  toggleIframe() {
    this.searchService.enabledIframe.next(!this.isEnabledIframe)
  }

  toggleCharts() {
    this.searchService.enabledCharts.next(!this.isEnabledCharts)
  }

  shouldDisplayIframeButton() {
    return this.authenticators.find(a => a.name === 'Microsoft')?.checked
  }

  logout() {
    this.authService.logout().subscribe(
      (data: any) => {
        sessionStorage.clear()
        localStorage.clear()
        window.location.reload()
      },
      (error: any) => {
        sessionStorage.clear()
        localStorage.clear()
        console.log(error)
        window.location.reload()
      }
    )
  }

}
