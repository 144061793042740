<div class="container">
  <button mat-icon-button [matMenuTriggerFor]="menu" class="mat_icon_button">
    <mat-icon class="mat_icon">person_outline</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="mat_menu">
    <div class="profile-buttons-container" [ngClass]="{'profile-buttons-container-border': authenticators.length > 0}">
      <button mat-menu-item mat-flat-button color="basic" class="submit-button-profile" (click)="logout()" type="submit">Log Out</button>
      <button mat-menu-item mat-flat-button color="basic" class="submit-button-profile" (click)="toggleTheme()" type="submit">Switch to {{mode}} Mode</button>
      <button mat-menu-item mat-flat-button color="basic" class="submit-button-profile" *ngIf="shouldDisplayIframeButton()" (click)="toggleIframe()" type="submit">
        {{ isEnabledIframe ? 'Disable iframe' : 'Enable iframe' }}
      </button>
      <button mat-menu-item mat-flat-button color="basic" class="submit-button-profile" (click)="toggleCharts()" type="submit">{{ isEnabledCharts ? 'Disable charts' : 'Enable charts'}}</button>
    </div>
    <mat-option *ngFor="let a of authenticators" [value]="a.name">
      <mat-slide-toggle [checked]="!a.expired" (click)="toggleChange($event, a.idp, a.expired)" [ngClass]="{'custom-slide-toggle-thumb': true, 'custom-slide-toggle-bar': true}">
        <span class="authenticator-label">
          {{a.idp}}
        </span>
      </mat-slide-toggle>
    </mat-option>
  </mat-menu>
</div>
