<div>
    <ng-template [ngIf]="provider">
        <span class="authorship">{{provider}}</span>
    </ng-template>
    <ng-template [ngIf]="url">
        <span class="authorship-url">{{url}}</span>
    </ng-template>
    <!-- <ng-template [ngIf]="author">
        <span class="authorship">&ensp;|&ensp;{{author}}</span>
    </ng-template> -->
</div>