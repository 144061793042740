// oauth2.service.ts
import { Injectable } from '@angular/core';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class OauthService {
  private oauthInstance: OAuthService | null = null;
  private isMicrosoft: boolean = false;
  public events: any;

  constructor(private _oauthInstance: OAuthService) {
    this.oauthInstance = this._oauthInstance
    this.isMicrosoft = false
    this.events = this._oauthInstance.events
  }

  loadConfig(oauthConfig: AuthConfig): void {
    this.oauthInstance?.configure(oauthConfig)
    this.oauthInstance?.setStorage(localStorage);
    this.oauthInstance?.loadDiscoveryDocumentAndTryLogin();
    if(oauthConfig?.issuer?.includes('microsoft'))
      this.isMicrosoft = true
  }

  getOAuthInstance(): OAuthService {
    if (this.oauthInstance === null) {
      throw new Error('OAuth is not initialized');
    } else {
      return this.oauthInstance;
    }
  }

  getType(): String | null {
    if(this.isMicrosoft)
      return 'microsoft'
    return null
  }

  initCodeFlow() {
    return this.oauthInstance?.initCodeFlow()
  }

  getAccessToken() {
    return this.oauthInstance?.getAccessToken()
  }
}
